import React from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  selectCallCentreNumber,
  selectDonorInfo,
  selectFormErrors,
  selectFormLanguage,
  selectIsCompanyGift,
  selectIsPrintReceipt,
  selectLoyaltyCardNumber,
} from "redux/form/form.selector";
import {
  selectAuthenticationToken,
  selectCurrentForm,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  updateCallCentreNumber,
  updateDonorInfo,
  updateFormErrors,
  updateIsCompanyGift,
  updateIsPrintReceipt,
} from "redux/form/form.reducer";
import {
  updateCurrentForm,
  updatePopup,
  updatePresValue,
} from "redux/helpers/helpers.reducer";
import { ValidationErrors } from "utils/interfaces";

import {
  CompanyGiftText,
  CompanyGiftContainer,
  RadioLabel,
  RadioContainer,
  CallCenterEmailDisclaimer,
  CCEmailText,
} from "./donor-info.styles";

import {
  BottomButtonsContainer,
  NextStepButton,
  SectionHeading,
  RequiredText,
  InputRow,
  InputContainer,
  RedStar,
  Input,
  ErrorMessage,
  FormPartContainer,
  CheckboxContainer,
} from "global";

import BackButton from "components/back-button/back-button.component";
import {
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";

import { schemaConstructor } from "utils/validation-schemas";
import { useTranslation } from "react-i18next";
import AddressComplete from "components/address-complete/address-complete.component";
import { useSearchParams } from "react-router-dom";
import analyticsAPI from "utils/analyticsAPI";
import lookup from "country-code-lookup";
import { CountryCode } from "libphonenumber-js";
import {
  countriesCollection,
  getTitleSelectValues,
  nameRegex,
  popupMessages,
  provincesCollection,
  specialCharacterRegex,
  statesCollection,
} from "utils/variables";
import Select from "components/select/select.component";
import { ValidationError } from "yup";

const DonorInfo: React.FC = () => {
  // URL parameters
  const [searchParams] = useSearchParams();
  const presValueURL = searchParams.get("pres") || searchParams.get("s_pres");
  const source = searchParams.get("source") || searchParams.get("s_cscid");

  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const donorInfo = useAppSelector(selectDonorInfo);
  const formErrors = useAppSelector(selectFormErrors);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const isPrintReceipt = useAppSelector(selectIsPrintReceipt);
  const callCentreNumber = useAppSelector(selectCallCentreNumber);
  const authenticationToken = useAppSelector(selectAuthenticationToken);
  const presValue = useAppSelector(selectPresValue);
  const popup = useAppSelector(selectPopup);
  const formLanguage = useAppSelector(selectFormLanguage);
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  // LOCAL STATE
  const { t } = useTranslation();
  const isDesktop = window.innerWidth > 768;
  const isCCFDForm =
    (source === "fundraising_direct" && Boolean(authenticationToken)) ||
    presValue === "CC";

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "yes") {
      dispatch(updateIsCompanyGift(true));
    } else {
      dispatch(updateIsCompanyGift(false));
      dispatch(updateDonorInfo({ ...donorInfo, companyName: "" }));
    }
  };

  const handleInputAndSelect = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.currentTarget;

    if (name === "email" && !presValueURL) dispatch(updatePresValue("default"));

    if (["firstName", "lastName", "companyName"].includes(name)) {
      if (nameRegex.test(value))
        dispatch(updateDonorInfo({ ...donorInfo, [name]: value }));
    } else if (name === "country") {
      dispatch(
        updateDonorInfo({ ...donorInfo, provinceState: "", [name]: value }),
      );
    } else {
      if (
        !["title", "addressLine2", "email"].includes(name) &&
        specialCharacterRegex.test(value)
      )
        return;

      dispatch(
        updateDonorInfo({
          ...donorInfo,
          [name]: name === "addressLine2" ? value.replace(/=/g, "") : value,
        }),
      );
    }
  };

  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.currentTarget.value;
    const regex = /^\+?(\d[-.\d]*)?$|^$/;

    if (regex.test(targetValue))
      dispatch(updateDonorInfo({ ...donorInfo, phoneNumber: targetValue }));
  };

  const handleNextForm = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    analyticsAPI.handleNextStep(appInsights, { currentForm });
    const schema = schemaConstructor("donorInfo", {
      locale: lookup.byCountry(donorInfo.country)?.iso2 as CountryCode,
      presValue,
      isCCFDForm,
      isAuthenticatedForm: Boolean(authenticationToken),
    });

    try {
      // Validate
      await validate(
        { ...donorInfo, loyaltyCardNumber, isCompanyGift, callCentreNumber },
        schema,
      );
      dispatch(updateCurrentForm(currentForm + 1));
      dispatch(updateFormErrors({}));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);

      // Display popup
      dispatch(updateFormErrors(errors));
      dispatch(
        updatePopup({
          ...popup,
          message: popupMessages.errorsFixed,
          isError: true,
          isActive: true,
          isLoading: false,
        }),
      );
    }
  };

  const handleIsPrintReceipt = () => {
    !isPrintReceipt
      ? dispatch(
          updateDonorInfo({
            ...donorInfo,
            email: process.env.REACT_APP_DONOR_SERVICES_EMAIL,
          }),
        )
      : dispatch(updateDonorInfo({ ...donorInfo, email: "" }));

    dispatch(updateIsPrintReceipt(!isPrintReceipt));
  };

  return (
    <FormPartContainer currentForm={currentForm} formID={1}>
      <SectionHeading>{t("Provide your info")}</SectionHeading>
      <RequiredText>* {t("Required info")}</RequiredText>

      {/* To wrap all inputs with one container */}
      <div
        onKeyDown={(e: React.KeyboardEvent) => {
          if (isDesktop && e.key === "Enter") handleNextForm(e);
        }}
      >
        {(isCCFDForm || Boolean(authenticationToken)) && (
          <>
            <div>
              <CompanyGiftText>{t("Company gift")}</CompanyGiftText>
              <CompanyGiftContainer onChange={handleRadioChange}>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioYes"
                    name="companyGift"
                    value="yes"
                    defaultChecked={isCompanyGift}
                  />
                  <RadioLabel htmlFor="radioYes">{t("Yes")}</RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioNo"
                    defaultChecked={!isCompanyGift}
                    name="companyGift"
                    value="no"
                  />
                  <RadioLabel htmlFor="radioNo">{t("No")}</RadioLabel>
                </RadioContainer>
              </CompanyGiftContainer>
            </div>
            {isCompanyGift && (
              <InputContainer>
                <label htmlFor="companyName">
                  <RedStar>*</RedStar> {t("Company Name")}:
                </label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  onChange={handleInputAndSelect}
                  value={donorInfo.companyName}
                  isError={formErrors.companyName}
                  maxLength={200}
                />
                {formErrors.companyName && (
                  <ErrorMessage>
                    <span>!</span> {t(formErrors.companyName)}
                  </ErrorMessage>
                )}
              </InputContainer>
            )}
          </>
        )}
        {!isCCFDForm && !authenticationToken && (
          <InputContainer>
            <label htmlFor="email">
              <RedStar>*</RedStar> {t("Email Address")}:
            </label>
            <Input
              type="email"
              name="email"
              id="email"
              onChange={handleInputAndSelect}
              value={donorInfo.email}
              isError={formErrors.email}
              maxLength={150}
              disabled={isPrintReceipt}
            />
            {formErrors.email && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.email)}
              </ErrorMessage>
            )}
          </InputContainer>
        )}

        <InputRow>
          <InputContainer>
            <label htmlFor="title"> {t("Title")}:</label>
            <Select
              name="title"
              id="title"
              onChange={handleInputAndSelect}
              value={donorInfo.title}
              optionsData={getTitleSelectValues(formLanguage)}
              withTranslation
              withValueTranslation
            />
          </InputContainer>

          <InputContainer>
            <label htmlFor="firstName">
              <RedStar>*</RedStar> {t("First Name")}:
            </label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              onChange={handleInputAndSelect}
              value={donorInfo.firstName}
              isError={formErrors.firstName}
              maxLength={50}
            />
            {formErrors.firstName && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.firstName)}
              </ErrorMessage>
            )}
          </InputContainer>
          <InputContainer>
            <label htmlFor="lastName">
              <RedStar>*</RedStar> {t("Last Name")}:
            </label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              onChange={handleInputAndSelect}
              value={donorInfo.lastName}
              isError={formErrors.lastName}
              maxLength={100}
            />
            {formErrors.lastName && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.lastName)}
              </ErrorMessage>
            )}
          </InputContainer>
        </InputRow>

        {!isCCFDForm && !authenticationToken && (
          <>
            <div>
              <CompanyGiftText>{t("Company gift")}</CompanyGiftText>
              <CompanyGiftContainer onChange={handleRadioChange}>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioYes"
                    name="companyGift"
                    value="yes"
                    defaultChecked={isCompanyGift}
                  />
                  <RadioLabel htmlFor="radioYes">{t("Yes")}</RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioNo"
                    defaultChecked={!isCompanyGift}
                    name="companyGift"
                    value="no"
                  />
                  <RadioLabel htmlFor="radioNo">{t("No")}</RadioLabel>
                </RadioContainer>
              </CompanyGiftContainer>
            </div>
            {isCompanyGift && (
              <InputContainer>
                <label htmlFor="companyName">
                  <RedStar>*</RedStar> {t("Company Name")}:
                </label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  onChange={handleInputAndSelect}
                  value={donorInfo.companyName}
                  isError={formErrors.companyName}
                  maxLength={200}
                />
                {formErrors.companyName && (
                  <ErrorMessage>
                    <span>!</span> {t(formErrors.companyName)}
                  </ErrorMessage>
                )}
              </InputContainer>
            )}
          </>
        )}

        {currentForm === 1 && (
          <AddressComplete
            countryToLookup={donorInfo.country}
            addressType="donor"
          />
        )}
        <InputContainer>
          <label htmlFor="addressLine2"> {t("Address 2")}: </label>
          <Input
            type="text"
            name="addressLine2"
            id="addressLine2"
            onChange={handleInputAndSelect}
            value={donorInfo.addressLine2}
            maxLength={200}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="city">
            <RedStar>*</RedStar> {t("City")}:
          </label>
          <Input
            type="text"
            name="city"
            id="city"
            onChange={handleInputAndSelect}
            value={donorInfo.city}
            isError={formErrors.city}
            maxLength={50}
          />
          {formErrors.city && (
            <ErrorMessage>
              <span>!</span> {t(formErrors.city)}
            </ErrorMessage>
          )}
        </InputContainer>
        <InputRow>
          {donorInfo.country === "Canada" ||
          donorInfo.country === "United States" ? (
            <InputContainer>
              <label htmlFor="provinceState">
                <RedStar>*</RedStar> {t("State / Province")}:
              </label>
              <Select
                name="provinceState"
                id="provinceState"
                onChange={handleInputAndSelect}
                value={donorInfo.provinceState}
                isError={formErrors.provinceState}
                optionsData={
                  donorInfo.country === "Canada"
                    ? provincesCollection
                    : statesCollection
                }
                withTranslation={donorInfo.country === "Canada"}
              />
              {formErrors.provinceState && (
                <ErrorMessage>
                  <span>!</span> {t(formErrors.provinceState)}
                </ErrorMessage>
              )}
            </InputContainer>
          ) : (
            <InputContainer>
              <label htmlFor="provinceState">
                <RedStar>*</RedStar> {t("State / Province")}:
              </label>
              <Input
                type="text"
                name="provinceState"
                id="provinceState"
                onChange={handleInputAndSelect}
                value={donorInfo.provinceState}
                maxLength={50}
              />
              {formErrors.provinceState && (
                <ErrorMessage>
                  <span>!</span> {t(formErrors.provinceState + " Input")}
                </ErrorMessage>
              )}
            </InputContainer>
          )}
          <InputContainer>
            <label htmlFor="postal">
              <RedStar>*</RedStar> {t("Postal")}:
            </label>
            <Input
              type="text"
              name="postal"
              id="postal"
              onChange={handleInputAndSelect}
              value={donorInfo.postal}
              isError={formErrors.postal}
              maxLength={7}
            />
            {formErrors.postal && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.postal)}
              </ErrorMessage>
            )}
          </InputContainer>
          <InputContainer>
            <label htmlFor="country">
              <RedStar>*</RedStar> {t("Country")}:
            </label>
            <Select
              name="country"
              id="country"
              onChange={handleInputAndSelect}
              value={donorInfo.country}
              isError={formErrors.country}
              optionsData={countriesCollection}
            />
            {formErrors.country && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.country)}
              </ErrorMessage>
            )}
          </InputContainer>
        </InputRow>
        <InputContainer>
          <label htmlFor="phoneNumber"> {t("Phone Number")}: </label>
          <Input
            type="phone"
            maxLength={50}
            name="phoneNumber"
            id="phoneNumber"
            onChange={handlePhoneNumber}
            value={donorInfo.phoneNumber}
            isError={formErrors.phoneNumber}
          />
          {formErrors.phoneNumber && (
            <ErrorMessage>
              <span>!</span> {t(formErrors.phoneNumber)}
            </ErrorMessage>
          )}
        </InputContainer>
        {isCCFDForm && (
          <CallCenterEmailDisclaimer data-cy="callCentreReceiptBlurb">
            {t("Receipts blurb")}
          </CallCenterEmailDisclaimer>
        )}
        {(isCCFDForm || Boolean(authenticationToken)) && (
          <InputContainer>
            <label htmlFor="email">
              <RedStar>*</RedStar> {t("Email Address")}:
            </label>
            {isCCFDForm && (
              <CCEmailText>{t("Valid email address")}</CCEmailText>
            )}
            <Input
              type="email"
              name="email"
              id="email"
              onChange={handleInputAndSelect}
              value={donorInfo.email}
              isError={formErrors.email}
              maxLength={150}
              disabled={isPrintReceipt}
            />
            {formErrors.email && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.email)}
              </ErrorMessage>
            )}
          </InputContainer>
        )}

        {isCCFDForm && (
          <>
            <CheckboxContainer style={{ marginBottom: "25px" }}>
              <input
                type="checkbox"
                id="isPrintReceipt"
                checked={isPrintReceipt}
                onChange={handleIsPrintReceipt}
              />
              <label htmlFor="isPrintReceipt">
                {t("Print receipt requested")}
              </label>
            </CheckboxContainer>

            {source !== "fundraising_direct" && (
              <InputContainer>
                <label htmlFor="callCentreNumber">
                  <RedStar>*</RedStar> {t("Contact Centre Call Number")}:
                </label>
                <Input
                  type="text"
                  name="callCentreNumber"
                  id="callCentreNumber"
                  value={callCentreNumber}
                  maxLength={255}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(updateCallCentreNumber(e.currentTarget.value));
                  }}
                  isError={formErrors.callCentreNumber}
                />
                {formErrors.callCentreNumber && (
                  <ErrorMessage>
                    <span>!</span> {t(formErrors.callCentreNumber)}
                  </ErrorMessage>
                )}
              </InputContainer>
            )}
          </>
        )}
      </div>
      <BottomButtonsContainer>
        <BackButton />

        <NextStepButton onClick={handleNextForm}>
          {t("Next Step")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default DonorInfo;
